import { NgModule } from '@angular/core';
import { BottomSheetModule } from './bottom-sheet/bottom-sheet.module';
import { ConsumptionLineModule } from './consumption-line/consumption-line.module';
import { RadioGroupModule } from './radio-group/radio-group.module'
import { TabGroupModule } from './tab-group/tab-group.module'
import { SelectionModule } from './selection/selection.module';
import { UtilsService } from '@library/utils/services/utils.service';
import { WarningIconModule } from './warning-icon/warning-icon.module'
import { EmbeddedSettingsFrameModule } from './embedded-settings-frame/embedded-settings-frame.module'

import { IllustratedPageModule } from './illustrated-page/illustrated-page.module';
import { AccordionModule } from './accordion/accordion-item.module'
import { BlockNotificationModule } from './block-notification/block-notification.module'
import { ElDeploymentTimeModule } from './deployment-time/deployment-time.module'
import { ElHeadingModule } from './el-heading/el-heading.module'
import { EmailBadgeModule } from './email-badge/email-badge.module'
import { InputPasswordModule } from './input-password/input-password.module'
import { PlusMinusModule } from './plus-minus/plus-minus.module'
import { SliderPickerModule } from './slider-picker/slider-picker.module'
import { StepInputTimeModule } from './step-input-time/step-input-time.module'
import { TemperatureRangeModule } from './temperature-range/temperature-range.module'
import { YesNoModule } from './yes-no/yes-no.module'
import { ActivableIconModule } from './activables/activable-icon/activable-icon.module'
import { ActivableIconTextModule } from './activables/activable-icon-text/activable-icon-text.module'
import { ActivableTextModule } from './activables/activable-text/activable-text.module'
import { LayoutModule } from '../layout/layout.module'
import { AtomsModule } from '../atoms/atoms.module'
import { InputModule } from './input/input.module';
import { StepSliderModule } from './step-slider/step-slider.module';
import { DatePickerModule } from '@library/ui/molecules/date-picker/date-picker.module';

const Modules = [
  AccordionModule,
  BlockNotificationModule,
  BottomSheetModule,
  ConsumptionLineModule,
  ElDeploymentTimeModule,
  ElHeadingModule,
  EmailBadgeModule,
  EmbeddedSettingsFrameModule,
  BottomSheetModule,
  InputPasswordModule,
  PlusMinusModule,
  RadioGroupModule,
  SelectionModule,
  SliderPickerModule,
  StepInputTimeModule,
  TabGroupModule,
  TemperatureRangeModule,
  WarningIconModule,
  YesNoModule,
  ActivableIconModule,
  ActivableIconTextModule,
  ActivableTextModule,
  IllustratedPageModule,
  LayoutModule,
  AtomsModule,
  InputModule,
  StepSliderModule,
  DatePickerModule
];

@NgModule({
    imports: [...Modules],
    exports: [...Modules],
    providers: [ UtilsService ]
})
export class MoleculesModule { }
