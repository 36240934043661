<input
  class="text-90 is-family-regular"
  *ngIf="type !== 'number'"
  [type]="type"
  [placeholder]="placeholder"
  required
  [id]="unique"
  [formControl]="inputText"
  [autocomplete]="autocomplete"
  (focus)="onFocus()"
  (blur)="onBlur()">

<input
  [ngClass]="styleClass"
  class="text-90 is-family-regular"
  *ngIf="type === 'number'"
  type="number"
  [placeholder]="placeholder"
  required
  [id]="unique"
  [formControl]="inputText"
  (focus)="onFocus()"
  (blur)="onBlur()">

  <label [for]="unique" (mousedown)="clickOnIcon()">
    <app-icon
      *ngIf="hasEdit"
      name="edit"
      class="icon is-size-4 has-text-grey-light edit"></app-icon>
    <app-icon #clearIcon
      name="clear"
      class="icon is-size-4 has-text-grey-light is-cursor-pointer clear"></app-icon>
  </label>
