export enum EnumEnvs {
  PRODUCTION = 'prod',
  BETA = 'beta',
  INTEGRATION = 'inte',
  LOCAL = 'local'
}

export interface ConfigInterface {
  appUrl: string;
  showcaseUrl?: string;
  authUrl: string;
  logoutUrl?: string;
  invitationUrl?: string;
  termsAndCondtionsUrl?: string;
  privacyPolicyUrl?: string;
  clientId: string;
  prodApiUrl: string;
  isProdDomain: boolean;
  cookiePrefix: string;
  cookieDomain?: string;
  cookiePrefixCrossDomain?: string;
  webPusherUrl: string;
  webRTCSocketUrl?: string;
  apiUrl: string;
  langsUrl: string;
  syncApiUrl: string;
  appPrefix?: string;
  smartherFAQ?: string;
  appId?: string;
  env?: EnumEnvs;
  helpcenterUrl?: string;
  darkModeEnabled?: boolean;
  appName?: string;
  appVersion?: string;
  loginEliotUrl?: string;
  eliotClientApi?: string;
  postLoginUrl?: string;
  myUrl?: string;
  legalsUrl?: string;
  turnUrl?: string;
  authLegrandUrl?: string;
  webappMode?: boolean;
  alexaApp?: boolean;
  alexaClientId?: string;
  alexaStage?: string;
  alexaRedirectUri?: string;
  alexaScope?: string;
  alexaWeatherClientId?: string;
  alexaWeatherStage?: string;
  alexaWeatherRedirectUri?: string;
  alexaWeatherScope?: string;
  oauth2ApiUrl?: string;
  clientIdC100X?: string;
  clientIdMX?: string;
  clientIdEasyKit?: string
}
