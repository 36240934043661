import { Env } from 'typings'

export const environment: Partial<Env> = {
  production: true,
  domain: '.inte.vg-thermostat.com',
  invitationUrl: 'https://auth.inte.vg-thermostat.com/invitation/home/',
  authUrl: 'https://auth.inte.vg-thermostat.com/access/login/',
  apiUrl: 'https://app.inte.netatmo.com/api/',
  syncApiUrl: 'https://app.inte.netatmo.com/syncapi/v1/',
  cookiePrefix: 'intevgthermostatcom',
  webPusherUrl: 'wss://app.inte.netatmo.com/ws/',
  langsUrl: '/langs/',
  helpcenterUrl: 'https://helpcenter.netatmo.com/hc/',
  appName: 'app_thermostat_vaillant',
  myAccountUrl: 'https://myconnectedaccount.inte.vg-thermostat.com/',
  faqUrl: 'https://settings.inte.vg-thermostat.com/#/faq',
  app: 'eRelax',
  mapboxAccessToken: 'pk.eyJ1IjoibmV0YXRtbyIsImEiOiJjanh5Z2U3NmIwMDQ2M2xzOTFsaXA0M2k2In0.uxP5nswLVJvjcm-AsGEPfQ',
};
