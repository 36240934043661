import { createSelector } from '@ngrx/store';
import { Home } from './homes.interface';
import { HomesState } from './homes.state';
import { countryPrice } from './countriesPriceCorrespondance.const';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { getCurrentHomeModules } from '../modules/modules.selector';
import { ContractTypes } from '../contracts/contracts.interface';

const homesState = (state: SettingsState) => state.homes;
const modulesState = (state: SettingsState) => state.modules;

export const getHomes = createSelector(
  homesState,
  state => {
    return state.homes;
  }
);

export const getCurrentHomeId = createSelector(
  homesState,
  state => {
    return state.currentHomeId;
  }
);

export const isLoadingHomes = createSelector(
  homesState,
  state => {
    return state.loading;
  }
);

export const getErrorModalDisplay = createSelector(
  homesState,
  state => {
    return state.errorModalDisplay;
  }
);

export const getCurrentHome = createSelector(
  getHomes,
  getCurrentHomeId,
  (homes: Home[], id: string) => {
    return homes.find(home => home.id === id);
  }
);

export const getHomeUsers = createSelector(
  homesState,
  state => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);
    if (!home || !home.users) { return null; }
    return home.users;
  }
);

export const getAdminAccessCode = createSelector(
  homesState,
  state => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);
    if (!home || !home.admin_access_code) { return null; }
    return home.admin_access_code;
  }
);

export const getHomeCoordinates = createSelector(
  homesState,
  state => {
    const home = state.homes.find(h => h.id === state.currentHomeId);
    if (!home || !home.place.coordinates) { return null; }
    return home.place.coordinates;
  }
);

export const getHomeAltitude = createSelector(
  homesState,
  state => {
    const home = state.homes.find(h => h.id === state.currentHomeId);
    if (!home || typeof home.place.altitude === 'undefined') { return null; }
    return home.place.altitude;
  }
);

export const getHomeTimezone = createSelector(
  homesState,
  state => {
    const home = state.homes.find(h => h.id === state.currentHomeId);
    if (!home || !home.place.timezone) { return null; }
    return home.place.timezone;
  }
);

export const isHomeTemperatureControlled = createSelector(
  homesState,
  modulesState,
  (homes, modules) => {
    const home = homes.homes.find(h => h.id === homes.currentHomeId);
    const modulesHome = modules.all.filter(mod => mod.homeId === home.id);
    let temperatureControlled = false;

    modulesHome.forEach(module => {
      if (module.canControlTemperature) {
        temperatureControlled = true;
      }
    });

    return temperatureControlled;
  }
);

export const getHomeName = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home.name;
  }
);

export const getHomeAddress = createSelector(
  homesState,
  (home: HomesState) => {
    return home.homes[0].place.address;
  }
);
export const getCurrentHomeCountry = createSelector(
  getCurrentHome,
  (home: Home) => {
    return home?.place ? home.place.country : null;
  }
);

export const isFrance = createSelector(
  getCurrentHomeCountry,
  (country: string) => {
      return country === 'FR';
    }
);

export const getCurrentHomeCapabilities = createSelector(
  getCurrentHome,
  home => home?.capabilities ? home?.capabilities : []
);

export const isSupportedCountry = createSelector(
  getCurrentHomeCountry,
  country => {
    // supported countries are the ones included in countryPrice array and that do not take part of forbidden ones
    return (
      typeof countryPrice.find((c) => c.code === country) !== 'undefined'
    );
  }
);

export const electricitySchedule = createSelector(
  homesState,
  (homeState) => {
    const homes = homeState.homes;
    const home = homes.find(h => h.id === homeState.currentHomeId);
    if (!home || !home.schedules) {
      return null;
    }

    // Return selected electricity schedule
    return home.schedules.find(sched => sched.type === 'electricity' && sched.selected );
  }
);

export const currencySymbol = createSelector(
  getCurrentHomeCountry,
  isSupportedCountry,
  (country, isSupported) => {
    if (isSupported) {
      const countryObj = countryPrice.find((c) => c.code === country);
      if (countryObj) {
        return countryObj.currencySymbol;
      }
    }
    return '€';
  }
 );

export const getCurrentHomeSchedules = createSelector(
  getCurrentHome,
  home => home?.schedules ? home.schedules : []
);

export const supportElectricitySchedule = createSelector(
  getCurrentHomeCapabilities,
  capabilities =>
    capabilities ? capabilities.some(
      (capability) => capability.name === 'electricity_contract' && capability.available
    ) : false
);

export const getCurrentHomeElectricitySchedules = createSelector(
  getCurrentHomeSchedules,
  schedules => schedules.filter(s => s.type === ContractTypes.consumption)
);

export const productionElectricitySchedule = createSelector(
  getCurrentHomeSchedules,
  schedules => schedules.find(s => s.type === ContractTypes.production)
);
export const hasDefaultElectricitySchedule = createSelector(
  getCurrentHomeElectricitySchedules,
  electricitySchedules => electricitySchedules.some(s => s.default)
);
export const defaultElectricityContractId = createSelector(
  getCurrentHomeElectricitySchedules,
  electricitySchedules => electricitySchedules.find(s => s.default) ? electricitySchedules.find(s => s.default).id : null
);

export const electricityPowerThreshold = createSelector(
  getCurrentHomeElectricitySchedules,
  electricitySchedules => electricitySchedules[0]?.power_threshold
);

export const electricityPowerUnit = createSelector(
  getCurrentHomeElectricitySchedules,
  electricitySchedules => electricitySchedules[0]?.contract_power_unit
);

export const getCurrentHomePersons = createSelector(
  getCurrentHome,
  home => home.persons || []
);

export const currentStatusLoaded = createSelector(
  getCurrentHome,
  home => home.status_loaded
)

export const currentConfigLoaded = createSelector(
  getCurrentHome,
  home => home.config_loaded
)

export const currentHomeHasNDL = createSelector(
  getCurrentHomeModules,
  (modules) => modules.findIndex(m => m.type === 'NDL') >= 0,
)
export const hasBNSE = createSelector(
  getCurrentHomeModules,
  (modules) => modules.some(mod => mod.type === 'BNSE')
)

export const getHomeType = createSelector(
  homesState,
  state => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);

    if (!home || !home.home_type) {
      return 'unknown';
    }
    return home.home_type;
  }
);

export const heatingSchedules = createSelector(
  getCurrentHome,
  (home) =>  home?.schedules?.filter(schedule => schedule.type === 'therm')
);

export const golbalThermoMode = createSelector(
  getCurrentHome,
  (home) => {
      return home.temperature_control_mode
  }
);
export const thermoMode = createSelector(
  getCurrentHome,
  (home) => {
      return home.therm_mode
  }
);

export const thermEndDate = createSelector(
  getCurrentHome,
  (home) => {
      return home.therm_mode === 'hg' || home.therm_mode === 'away' ?  home.therm_mode_endtime : null;
  }
);

export const supportsProduction = createSelector(
  getCurrentHomeModules,
  (modules) =>  modules.some(module => module.measured_elec_type === 'production')
);

export const supportsOverproduction = createSelector(
  getCurrentHomeModules,
  (modules) => {
    const hasTotalConsumptionMeasurer = modules.some(module => {
      return module.type === 'NLPS' || (module.type === 'NLPC' && module.measured_elec_type === 'consumption' && module.measures_scope === 'total')
    })
    const hasTotalProductionMeasurer = modules.some(module => {
      return module.type === 'NLPC' && module.measured_elec_type === 'production' && module.measures_scope === 'total'
    })
    return hasTotalConsumptionMeasurer && hasTotalProductionMeasurer
  }
);

export const hgTemperature = createSelector(
  heatingSchedules,
  (schedules) => schedules.find(schedule => schedule.selected)?.hg_temp
);

export const homeThermostats = createSelector(
  getCurrentHomeModules,
  (modules) => modules.filter(mod => mod.type === 'NATherm1')
);

export const supportsCooling = createSelector(
  getCurrentHomeModules,
  (modules) => !!modules.find(module => module.config?.specifications?.temperature_control_mode?.values.length > 1) 
);
