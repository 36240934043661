import { TariffOptions, TariffOptionsType, TariffType, Tariffs } from "@library/utils/interfaces/tariff.interface";
import { TimetableSlot, Zone } from "../schedules/schedules.interface";

export enum PowerUnit {
  kVA = 'kVA',
  kW = 'kW',
}
export type PowerUnitType = PowerUnit.kVA | PowerUnit.kW;

export enum ContractTypes {
  production = 'electricity_production',
  consumption = 'electricity',
}
export type ContractType = ContractTypes.consumption | ContractTypes.production;

export interface ContractsState {
  all: ContractState[];
  powerUnit: PowerUnit;
}

export interface ContractState extends Contract {
  peak?: number;
  off_peak?: number;
  offPeakPeriods: [number, number] [];
  basicPrice?: number;
}

export type Contract = {
  _id?: string; // id of contract
  schedule_id?: string;   // id of corresponding schedule
  contractType?: string; 
  home_id: string;
  type?: string; // cannot use type for actions so we use contractType for store and type when we send to api 
  is_dynamic?: boolean;
  tariff?: TariffType;
  tariff_option?: TariffOptionsType;
  contract_power_unit?: PowerUnitType;
  zones: Zone[];
  timetable: TimetableSlot[];
  production_contract_type?: string; 
  provider_info?: {name: string};
  power_threshold?:  number;
  name: string;
  default: boolean;
}

export const initialContractState: ContractsState = {
  all: [],
  powerUnit: PowerUnit.kW
}

export const initialContractConsumption: Partial<ContractState> = {
  power_threshold: 9, // 3, 6, 9, 12, 15, 18, 24, 30, 36
  tariff: Tariffs.EDF,
  tariff_option: TariffOptions.BASIC,
  type: ContractTypes.consumption,
  contract_power_unit: PowerUnit.kVA,
  zones: [],
  timetable: [],
  offPeakPeriods: [],
  contractType: ContractTypes.consumption,
}