import { SchedulesState } from './schedules.state';
import { createSelector } from '@ngrx/store';

const schedulesState = (state) => state.schedules as SchedulesState;

export const getQuizzData = createSelector(
  schedulesState,
  state => {
    return state.scheduleToAdd.quizzData;
  }
);

export const currentSelectedThermSchedule = createSelector(
  schedulesState,
  state => {    
    return state.schedules.find(schedule => schedule.type === 'therm' && schedule.selected)
  }
); 