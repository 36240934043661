<div
class="lay-content"
[ngClass]="{
  'lay-content': true,
  'is-direction-row': direction === 'row',
  'is-direction-column': direction === 'column'}">
<ng-content></ng-content>
</div>
<app-icon class="navigation-icon is-size-4"  *ngIf="!(userAgent.isNativeApp() && userAgent.isAndroid()) && !showSpinner" name="chevron-navigation"></app-icon>
<lay-button *ngIf="showSpinner">
  <button
    class="is-loading">
  </button>
</lay-button>
