<ng-container *ngIf="(globalError$ | async) as payload">

  <app-modal [display]="errorDisplay">

    <app-modal-body>

      <div *ngIf="payload.message.icon === 'fail'; else iconTemplate" class="has-margin-bottom-normal">

        <app-warning-icon></app-warning-icon>

      </div>

      <ng-template #iconTemplate>
        <app-icon
          [name]="payload.message.icon"
          class="is-flex is-justify-center has-text-danger is-size-3 is-large"
        >
        </app-icon>
      </ng-template>

      <el-heading
        [text]="payload.message.title"
        class="is-flex is-justify-center is-size-5 has-text-black"
        [subtext]="payload.message.subtitle">
      </el-heading>

      <app-text
        class="is-flex is-justify-center is-block has-padding-normal is-word-break has-text-black"
        [text]="payload.message.text">
      </app-text>

      <ng-container *ngIf="payload.message.linkText && payload.message.linkUrl">
        <app-text
          [text]="payload.message.linkText"
          [url]="payload.message.linkUrl"
          class="is-flex is-justify-center has-padding-small has-margin-bottom-small is-block is-family-semiBold has-text-netatmo"
        >
        </app-text>
      </ng-container>

      <app-button
        *ngFor="let action of payload.actions"
        class="is-flex-button has-margin-bottom-small"
        [styleClass]="action.buttonConfig.styleClass"
        [text]="action.buttonConfig.text"
        (click)="onClick(action.action)">
      </app-button>

    </app-modal-body>

    <app-modal-footer *ngIf="payload.actions.length === 0">

      <app-text
        [text]="'common-settings.__CANCEL' | LANG"
        class="has-text-grey is-cursor-pointer"
        (click)="onClick()">
      </app-text>

    </app-modal-footer>

  </app-modal>

</ng-container>

